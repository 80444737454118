import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class TemplateDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    description?: string;
    alias?: string;
    platform?: Nullable<string> = 'android';
    is_default?: boolean = false;
    project_guid?: string;

    constructor({ id, guid, name, description, alias, platform, is_default, project_guid } : { id?: Nullable<number>, guid?: Nullable<string>, name?: string, description?: string, alias?: string, template_id: Nullable<number>, platform?: Nullable<string>, is_default?: boolean, project_guid?: string}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.platform = platform ?? 'android';
        this.is_default = is_default ?? false;
        this.project_guid = project_guid;
    }
}

export default class Template extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private alias: Nullable<string>;
    private platform: Nullable<string>;
    private isDefault: Nullable<boolean>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        alias: Nullable<string>,
        platform: Nullable<string> = 'android',
        isDefault: Nullable<boolean> = false
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.alias = alias;
        this.platform = platform;
        this.isDefault = isDefault;
        this.assertInvariants();
    }

    static create(dto: TemplateDTO): Template
    {
        return new Template(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.alias,
            dto.platform,
            dto.is_default
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    setAlias(alias: Nullable<string>): void
    {
        this.alias = alias;
    }

    getAlias(): Nullable<string>
    {
        return this.alias;
    }

    setPlatform(platform: Nullable<string>): void
    {
        this.platform = platform;
    }

    getPlatform(): Nullable<string>
    {
        return this.platform;
    }

    setDefault(isDefault: boolean): void
    {
        this.isDefault= isDefault;
    }
}